import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import NextLink from "common/NextLink"
import SeoMetaData from "common/SeoMetaData"
import {
  StaticPropsFactory,
  StaticPropsFactoryProps,
} from "common/StaticPropsFactory"
import nextLinks from "next.links"
import Shared from "theme/shared"

const Styled = {
  Wrapper: styled.div`
    text-align: center;
    position: relative;
    min-height: 511px;
    flex: 1;
    padding-top: 28px;

    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.62)
    );

    ${(p) => p.theme.breakpoints.up("md")} {
      padding-top: 60px;
    }
  `,
  Background: styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  `,
  Title: styled(Typography)`
    font-size: 48px;
    font-weight: bold;
    line-height: 1.46;
    color: ${(p) => p.theme.colors.white};
    text-shadow: 0 0 40px ${(p) => p.theme.colors.black};
    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 78px;
    }
  `,
  SubTitle: styled(Typography)`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: ${(p) => p.theme.colors.white};
    text-shadow: 0 0 40px ${(p) => p.theme.colors.black};
    ${(p) => p.theme.breakpoints.up("md")} {
      font-size: 23px;
    }
  `,
  Buttons: styled.div`
    margin-top: 18px;
    display: inline-flex;
    gap: 17px;
    ${(p) => p.theme.breakpoints.up("md")} {
      margin-top: 16px;
      gap: 24px;
    }
  `,
  Button: styled(Shared.Button)`
    ${(p) => p.theme.breakpoints.down("sm")} {
      font-size: 12px;
      width: 132px;
    }
  `,
}

export const getStaticProps = StaticPropsFactory("NotFound")

const Page404 = ({ seo }: StaticPropsFactoryProps) => {
  return (
    <Styled.Wrapper>
      <SeoMetaData data={seo} />
      <Styled.Background
        srcSet="/images/20_FRD_FSD_47648@3x.png 3x, /images/20_FRD_FSD_47648@2x.png 2x, /images/20_FRD_FSD_47648.png 1x"
        src="/images/20_FRD_FSD_47648.png"
      />
      <Styled.Title>שגיאה 404</Styled.Title>
      <Styled.SubTitle>מצטערים, העמוד אליו ביקשת להגיע לא נמצא</Styled.SubTitle>
      <Styled.Buttons>
        <NextLink href="/" passHref>
          <Styled.Button variant="contained">חזרה לדף הבית</Styled.Button>
        </NextLink>
        <NextLink href={nextLinks.contactUs} passHref>
          <Styled.Button variant="contained">צור קשר</Styled.Button>
        </NextLink>
      </Styled.Buttons>
    </Styled.Wrapper>
  )
}

export default Page404
